import "tachyons"
import SimpleReactLightbox from "simple-react-lightbox"
import { Provider } from "reakit"
import React from "react"

const startsWith = (path, value) => value.indexOf(path) === 0

export const shouldUpdateScroll = ({ prevRouterProps, routerProps }) => {
  const previousPath = prevRouterProps?.location?.pathname
  const currentPath = routerProps.location.pathname
  return startsWith("/news/page/", currentPath) ||
    startsWith("/news/category/", currentPath) ||
    startsWith("/media/page/", currentPath) ||
    startsWith("/media/category/", currentPath) ||
    currentPath ===
      "/disability/services/supported-independent-living/vacancies/" ||
    startsWith(
      "/disability/services/supported-independent-living/vacancies/state/",
      currentPath
    )
    ? false
    : true
}

export const onRouteUpdate = ({ prevLocation, location }) => {
  if (prevLocation && window && window.piTracker) {
    piTracker(location.href)
  }
}

export const wrapRootElement = ({ element }) => {
  return (
    <Provider>
      <SimpleReactLightbox>{element}</SimpleReactLightbox>
    </Provider>
  )
}
