module.exports = [{
      plugin: require('../../../../.yarn/__virtual__/gatsby-plugin-manifest-virtual-982f8eee62/0/cache/gatsby-plugin-manifest-npm-4.25.0-25ef1b75df-a9c755d0e5.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Life Without Barriers","short_name":"LWB","start_url":"/","background_color":"#fff","theme_color":"#49A743","display":"minimal-ui","icon":"../../libs/gatsby-config/src/images/icon.jpg","theme_color_in_head":true,"legacy":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ce1054635520ccccf1d7bf94d0d1ec45"},
    },{
      plugin: require('../../../../.yarn/__virtual__/gatsby-plugin-styled-components-virtual-b341f65625/0/cache/gatsby-plugin-styled-components-npm-5.25.0-c3f078133e-ce47704b1f.zip/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../../../.yarn/cache/gatsby-plugin-sentry-npm-1.0.1-ddd2d13e4a-ab53d0785f.zip/node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://af836861f1f34b17a73a34fec7f46ef9@sentry.io/1880587","ignoreErrors":["ResizeObserver loop limit exceeded","fb_xd_fragment"],"ignoreUrls":[{},{},{},{},{}],"environment":"prod","enabled":true},
    },{
      plugin: require('../../../../.yarn/__virtual__/gatsby-plugin-google-tagmanager-virtual-c33b14ffec/0/cache/gatsby-plugin-google-tagmanager-npm-4.25.0-f7c88884e6-e2804b450c.zip/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PHCS52B","includeInDevelopment":true,"gtmAuth":"muhxVMrB8NFJ4qXuZB8T0A","gtmPreview":"env-2","defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../../../../.yarn/cache/gatsby-plugin-web-vitals-npm-1.0.3-523192a8e4-c96a2f37bd.zip/node_modules/gatsby-plugin-web-vitals/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-20649335-1","metrics":["FID","TTFB","LCP","CLS","FCP"],"debug":false},
    },{
      plugin: require('../../../libs/gatsby-plugin-common/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../.yarn/unplugged/gatsby-virtual-5d5b0e9e5d/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
